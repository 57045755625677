import {mapGetters} from "vuex";

export default {
    computed: {
        ...mapGetters({
            isPageLoading: 'layout/isPageLoading',
            isLogin: 'authorize/isLogin',
            loginUser: 'authorize/userInfo'
        }),
    },
    data () {
        return {

        }
    },
    methods: {
        num2han(val) {
            const numKor = ["", "일", "이", "삼", "사", "오", "육", "칠", "팔", "구", "십"];                                  // 숫자 문자
            const danKor = ["", "십", "백", "천", "", "십", "백", "천", "", "십", "백", "천", "", "십", "백", "천"];    // 만위 문자열
            let result = "";

            if(val === 0 || val === '0') {
                return '영'
            }
            if(val && !isNaN(val)){
                // CASE: 금액이 공란/NULL/문자가 포함된 경우가 아닌 경우에만 처리

                val = val.toString()
                for(let i = 0; i < val.length; i++) {
                    let str = "";
                    const num = numKor[val.charAt(val.length - (i + 1))];
                    if(num !== "")   str += num + danKor[i];    // 숫자가 0인 경우 텍스트를 표현하지 않음
                    switch(i){
                        case 4:str += "만";break;     // 4자리인 경우 '만'을 붙여줌 ex) 10000 -> 일만
                        case 8:str += "억";break;     // 8자리인 경우 '억'을 붙여줌 ex) 100000000 -> 일억
                        case 12:str += "조";break;    // 12자리인 경우 '조'를 붙여줌 ex) 1000000000000 -> 일조
                    }

                    result = str + result;
                }

                // Step. 불필요 단위 제거
                if(result.indexOf("억만") > 0)    result = result.replace("억만", "억");
                if(result.indexOf("조만") > 0)    result = result.replace("조만", "조");
                if(result.indexOf("조억") > 0)    result = result.replace("조억", "조")
            }

            return result
        },
        removeTableHelper(event) {
            if(event.target) {
                event.target.classList.remove('table-help-notice');
            }
        }
    }
}