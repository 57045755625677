export default {
    namespaced: true,
    state: () => ({
        isLoading: false
    }),
    mutations: {
        setLoading (state, payload) {
            state.isLoading = payload
        }
    },
    actions: {

    },
    getters: {
        isPageLoading (state) {
            return state.isLoading
        }
    }
}