'use strict';
const config = {};
/**
 * 클라이언트용 페이지를 만듭니다.
 * ----------------------------------------------------------------------------------------------------
 * true 설정되어 있을경우 관리자 접속페이지의 모든 경로 앞에 /admin 이 추가됩니다.
 * false 접속 루트 전체가 관리자 페이지로 처리됩니다.
 */
config.useClient = false
config.devApiHost = 'http://localhost:3300'
config.prodApiHost = 'https://neoslight-api.wheeparam.com'

export default config;