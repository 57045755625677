import UserModel from '@/models/users.model'
export default {
    namespaced: true,
    //state: 해당 파일에서 관리하는 상태값 -> state를 변경하기 위해서는 mutations 내부의 변수를 commit으로 실행
    state: () => ({
        isLogin: false,
        userInfo: {
            id: 0,
            name: '',
            team_id: 0,
            team_name: '',
            position_name:'',
            rank_name:'',
            permission: {}
        }
    }),
    // mutations: state를 변경하기 위해 실행되는 것으로 비동기를 해야할 경우
    mutations: {
        setLogin (state) {
            state.isLogin = UserModel.isLogin()
        },
        setUserInfo (state, payload) {
            if(payload === null) {
                state.userInfo.id = 0
                state.userInfo.name = ''
                state.userInfo.team_id = 0
                state.userInfo.teamName = ''
                state.userInfo.position_name = ''
                state.userInfo.rank_name = ''
                state.userInfo.permission =  {}
            }
            else {
                for(let column in payload) {
                    if(typeof state.userInfo[column] !== 'undefined') {
                        state.userInfo[column] = payload[column]
                    }
                }
            }
        }
    },
    getters: {
        isLogin (state) {
            return state.isLogin
        },
        userInfo (state) {
            return state.userInfo
        }
    }
}