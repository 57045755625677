import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

const OneSignaleAppID = '0868c7cb-a423-44d7-8699-8b89d77bc39f';

Vue.config.productionTip = false

import '@/libraries/formatter.js'

// SweetAlert
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css';
const options = {
  confirmButtonColor: '#B81F24',
  cancelButtonColor: '#D9D9D9',
};
Vue.use(VueSweetalert2, options)

import VueProgressBar from 'vue-progressbar'

Vue.use(VueProgressBar, {
  color: '#B81F24',
  failedColor: 'red',
  height: '2px'
})

// mixins
import mixins from "@/mixins";
Vue.mixin(mixins);


// 새로고침등을 했을때 로그인이 되어있는지 여부를 다시 체크
import userModel from '@/models/users.model'
if(userModel.isLogin())
{
  store.commit('authorize/setLogin', true)
  store.commit('authorize/setUserInfo', JSON.parse(window.localStorage.getItem('userInfo')))
}

import '@/_scss/global.scss'

document.addEventListener('deviceready', OneSignalInit, false);
function OneSignalInit() {
  window.plugins.OneSignal.setAppId(OneSignaleAppID);

  window.plugins.OneSignal.setNotificationOpenedHandler(function(jsonData) {
    if(typeof jsonData.notification !== 'undefined'
        && typeof jsonData.notification.additionalData !== 'undefined'
        && typeof jsonData.notification.additionalData.signId !== 'undefined'
    ) {
      router.push('/signs/view/' + jsonData.notification.additionalData.signId );
    }
  });

  //Prompts the user for notification permissions.
  //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
  window.plugins.OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
    console.log("User accepted notifications: " + accepted);
  });
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
