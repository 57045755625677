<template>
  <v-app :style="{height:ui.height + 'px'}">
    <v-app-bar v-if="isLogin" app color="primary" dark elevation="0">
      <v-btn @click="ui.leftMenuOpened=!ui.leftMenuOpened" icon><v-icon>mdi-menu</v-icon></v-btn>

      <div class="d-flex align-center">
        <v-app-bar-title><router-link to="/" style="color:inherit;text-decoration: none">NEOSLIGHT</router-link></v-app-bar-title>
      </div>

      <v-spacer></v-spacer>

      <v-btn icon>
        <v-icon>mdi-bell</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-if="isLogin" fixed  temporary v-model="ui.leftMenuOpened">
      <template v-slot:prepend>
        <v-list  color="primary" dark>
          <v-list-item two-line>
            <v-list-item-avatar>
              <v-icon>mdi-account</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{loginUser.name}} {{loginUser.rank_name}}</v-list-item-title>
              <v-list-item-subtitle>{{loginUser.team_name}}</v-list-item-subtitle>

            </v-list-item-content>
          </v-list-item>
          <div class="pa-4 ps-4 pb-2 pt-0">
            <v-btn small block @click="signOut" elevation="0" color="white" light><v-icon left>mdi-logout</v-icon> 로그아웃</v-btn>
          </div>
        </v-list>

        <v-divider />

        <v-list dense>
          <v-list-item to="/">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>DASHBOARD</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider />

          <v-list-item to="/signs/N">
            <v-list-item-icon>
              <v-icon>mdi-file-sign</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>결재해야할 문서</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/signs/Y">
            <v-list-item-icon>
              <v-icon>mdi-file-sign</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>결재완료 문서</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/signs/D">
            <v-list-item-icon>
              <v-icon>mdi-file-sign</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>반려/취소 문서</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />

          <v-list-item to="/board/notice">
            <v-list-item-icon>
              <v-icon>mdi-bullhorn</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>공지사항</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/contacts">
            <v-list-item-icon>
              <v-icon>mdi-card-account-phone</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>비상연락망</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

      </template>
    </v-navigation-drawer>

    <v-main>
      <router-view/>
    </v-main>

    <vue-progress-bar></vue-progress-bar>
  </v-app>
</template>

<style lang="scss">
html, body {font-family: Roboto, 'Noto Sans KR', sans-serif}
.v-application {font-family: Roboto, 'Noto Sans KR', sans-serif}
</style>

<script>
import userModel from '@/models/users.model'
export default {
  name: 'App',
  data: () => ({
    ui: {
      leftMenuOpened: false,
      height:0
    }
  }),
  mounted() {
    this.onWindowResize()
    document.addEventListener('resize', this.onWindowResize)

    let customVH = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', customVH + 'px')
    window.addEventListener('resize', () => {
      let customVH = window.innerHeight * 0.01
      document.documentElement.style.setProperty(
          '--vh',
          customVH + 'px',
      )
    })
    
  },
  destroyed() {
    document.removeEventListener('resize', this.onWindowResize)
  },
  methods: {
    onWindowResize() {
      this.ui.height = window.innerHeight
    },
    signOut () {
      userModel.signOutProcess()
    }
  }
};
</script>

<style lang="scss">
html {
  -webkit-overflow-scrolling: touch;
  overflow:hidden;
  touch-action:none;
}
html, body {
  -webkit-font-smoothing: antialiased;
  overscroll-behavior-y: contain;
  scroll-behavior: unset !important;
  scroll-snap-type: none !important;
}
#app {
  height: calc(var(--vh, 1vh) * 100);
  overflow: scroll;
  .v-application--wrap {
    min-height: calc(var(--vh, 1vh) * 100) !important; // 덮어씌우기
  }
}
</style>