import vue from 'vue';
import store from '@/store';
import jwt from '@/plugins/jwt';

const exportObject = {};


exportObject.isLogin = () => {
    const accessToken = localStorage.getItem('accessToken');
    return !!(accessToken && accessToken !== 'undefined');
};


exportObject.loginProcess = async (payload) => {
    return await jwt.axios
        .post('/v1/users/authorize', {
            loginId: payload.loginId,
            loginPass: payload.loginPass,
        })
        .then(async (res) => {
            if (
                typeof res.data.accessToken !== 'undefined' &&
                typeof res.data.userData !== 'undefined'
            ) {
                localStorage.setItem('accessToken', res.data.accessToken);
                localStorage.setItem('refreshToken', res.data.refreshToken);

                store.commit('authorize/setLogin', true);

                await exportObject.getMyInfo(store.getters["authorize/userInfo"].id);

                exportObject.setDeviceToken();
            } else {
                vue.swal('Error', '사용자 로그인에 실패하였습니다', 'error');
                store.commit('authorize/setLogin', false);
                store.commit('authorize/setUserInfo', null);
                throw new Error('사용자 로그인에 실패');
            }
        });
};

exportObject.setDeviceToken = () => {
    try {
        if(typeof window.plugins === 'undefined') {
            throw new Error('Not initialized Plugins');
        }

        if(typeof window.plugins.OneSignal === 'undefined') {
            throw new Error('Not initialized OneSignal');
        }

        if(! store.getters["authorize/isLogin"]) {
            throw new Error('No Login');
        }

        let userId = store.getters["authorize/userInfo"].id * 1

        if(userId <= 0) {
            throw new Error('No Login, login id is 0');
        }

        window.plugins.OneSignal.setExternalUserId(userId + "", (results) => {
            console.log('Results of setting external user id');
            console.log(results);
        })
    }
    catch (e) {
        console.log(e);
    }
}

exportObject.getMyInfo = async () => {
    return await jwt.axios.get('/v1/users').then(res => {
        localStorage.setItem('userInfo', JSON.stringify(res.data));

        store.commit('authorize/setUserInfo', res.data)
    })
}


exportObject.signOutProcess = () => {
    vue
        .swal({
            title: '로그아웃',
            text: '로그아웃 하시겠습니까?',
            icon: 'question',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: '로그아웃',
            cancelButtonText: '취소',
        })
        .then((result) => {
            if (result.isConfirmed) {
                exportObject.signOut();
                location.reload();
            }
        });
};

/**
 * 사용자 로그아웃처리
 */
exportObject.signOut = () => {
    localStorage.removeItem('userInfo');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    store.commit('authorize/setLogin', false);
    store.commit('authorize/setUserInfo', null);
    location.reload();
};

export default exportObject;
