import Vue from 'vue'
import VueRouter from 'vue-router'
import usersModel from '@/models/users.model'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'dashboard', component: () => import(/* webpackChunkName: "dashboard" */ '../views/HomeView.vue') },
  { path: '/sign-in', name:'SignIn', component: () => import(/* webpackChunkName: "signIn" */ '../views/SignIn.vue'), meta: {requiredLogin: false}},
  { path: '/board/:brdKey', name:'BoardPostList',component: () => import(/* webpackChunkName: "board.posts" */ '../views/BoardPostList.vue')},
  { path: '/board/:brdKey/:postId', name:'BoardPostView',component: () => import(/* webpackChunkName: "board.posts.view" */ '../views/BoardPostView.vue')},
  { path: '/contacts', name:'ContactsList',component: () => import(/* webpackChunkName: "contacts" */ '../views/ContactsList.vue')},
  { path: '/signs', name:'SignsList',component: () => import(/* webpackChunkName: "signs.list" */ '../views/SignsList.vue')},
  { path: '/signs/:type', name:'SignsList',component: () => import(/* webpackChunkName: "signs.list" */ '../views/SignsList.vue')},
  { path: '/signs/view/:signId', name:'signsView',component: () => import(/* webpackChunkName: "signs.view" */ '../views/SignsView.vue'), meta:{isComment: false}},
  { path: '/signs/view/:signId/comments', name:'signsView',component: () => import(/* webpackChunkName: "signs.view" */ '../views/SignsView.vue'), meta: {isComment:true}},
  { path: '*', name:'Error404',component: () => import(/* webpackChunkName: "error404" */ '../views/Error404')},
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // 헤당페이지에 접근할때 로그인이 필요한지 여부를 meta 속성에서 가져온다.
  const requiredLogin = ! (typeof to.meta !== 'undefined' && typeof to.meta.requiredLogin !== 'undefined' && to.meta.requiredLogin === false)

  if(requiredLogin) {
    const isLogin = usersModel.isLogin();

    if(isLogin) {
      return next()
    }
    else {
      return next({path:'/sign-in', query:{redirectUri: to.fullPath}})
    }
  }
  else {
    return next()
  }
})

export default router
